import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
// import { createStore, applyMiddleware} from 'redux';
import { Router, Route, Switch } from 'react-router-dom';
// import thunk from 'redux-thunk';
// import reducer from './reducers';
import App from './containers/App';
import Auth0Login from './containers/Login/Auth0Login/Auth0Login';
import Auth0MFAVerification from './containers/Login/2FAVerification/Auth0MFAVerification';
import CodeVerification from './containers/CodeVerification/CodeVerification';
import ForgotPassword from './containers/Login/ForgotPassword/ForgotPassword';
import Dashboard from './containers/Dashboard/Dashboard';
import constants from './constants';
import { getObjectFromLocal } from './utilities/utils';
import store from './store';

import './style/style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//import '@fortawesome/fontawesome-free/css/all.min.css';
import history from './common/CreateBrowserHistory/CreateBrowserHistory';

// const store = createStore(reducer,  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),applyMiddleware(thunk),);
// const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
const routeUrl = constants.ROUTE_URL.length > 0 ? constants.ROUTE_URL : '';

const pathUrl = constants.PATH_URL;
//const history = createBrowserHistory();

function checkAuth(nextState, replace) {
	// Check the length of the browser history list.
	const result = window.history.length;
	if (result != 1) {
		const user = getObjectFromLocal('user');
		const userSession = sessionStorage.getItem('userSession');
		if (!user || !user.email || !userSession) {
			// Removing local and session storage variables after user logs off or the browser/tab closes.
			localStorage.removeItem('user');
			localStorage.removeItem('authSecret');
			sessionStorage.removeItem('userSession');
			window.document.cookie =
				'JSESSIONID=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Path=/pathUrl;';
			replace({
				pathname: routeUrl,
			});
		}
	} else {
		sessionStorage.setItem('userSession', 'Y');
	}
}


function handleUpdate() {
	window.scrollTo(0, 0);
}

render(
	<Provider store={store}>
		<Router history={history} onUpdate={() => handleUpdate()}>
			<Switch>
			{/* <App> */}
				
				<Route exact path={routeUrl + '/'} component={Auth0Login} />
				<Route exact path={routeUrl + '/step3'} component={Auth0MFAVerification} />
				<Route exact path={routeUrl + '/code'} component={CodeVerification} />
				<Route exact path={routeUrl + '/forgotpassword'} component={ForgotPassword} />
				<Route path={routeUrl + '/dashboard'} component={Dashboard} onEnter={checkAuth} />
			{/* </App> */}
			</Switch>
		</Router>
	</Provider>,
	document.getElementById('root')
);

// render(
// 	<React.StrictMode>
// 		<Provider store={store}>
// 	  		<App>
// 				  Hello
// 			</App>
// 	  	</Provider>
// 	</React.StrictMode>,
// 	document.getElementById('root')
//   );
