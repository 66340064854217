/* istanbul ignore file */
import store from '../../../store';
import actionTypes from '../../../actions/actionTypes';
import { apiPostForm, apiGet, apiPost, setObjectToLocal } from '../../../utilities/utils';

export const verifyAuth = (secret, code) => {
	return new Promise((resolve, reject) => {
		apiPostForm('VerifyAuthenticationCode', {
			'authenticationSecret': secret,
			'authenticationCode': code
		})
			.then(
				(res) => {
					resolve(res);
				},
				(error) => {
					reject(error);
				}
			)
			.catch((err) => {
				store.dispatch({
					type: actionTypes.VERIFY_AUTHENTICATION_ERROR,
					payload: err.response,
				});
			});
	});
};

export const getUserRole = () => {
	return new Promise((resolve, reject) => {
		apiGet('GetUserRole')
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_USER_ROLE,
					payload: res.data.data
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export function Auth0SetMFASessionToken(m_AuthenticationSecret, email) {
	return new Promise((resolve, reject) => {
		apiPostForm('SetMFASessionToken', {
			'authenticationSecret': m_AuthenticationSecret,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
				store.dispatch({
					type: actionTypes.VERIFY_AUTHENTICATION_ERROR,
					payload: err.response,
				});
			});
	});
}

export const auth0Login = (email) => {
	return new Promise((resolve, reject) => {
		apiPostForm('Login', { 'email':email })
			.then((res) => {
				store.dispatch({
					type: actionTypes.AUTH_LOGIN_SUCCESS,
					payload: true,
				});
				setObjectToLocal('authSecret', { secret: res.data.authSecret }),
					setObjectToLocal('user', { email: email }),
					sessionStorage.setItem('userSession', 'Y');
					return resolve();
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.AUTH_LOGIN_ERROR,
					payload: err.response,
				});
			});
	});
};
